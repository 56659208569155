import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Web3Context } from '../contexts/Web3Context'
import * as styles from './layout.module.css'

export default function Layout({ prompt, custom = null, altLogo = false, children }) {

	const { account, connectToWeb3, disconnectFromWeb3, web3Available } = useContext(Web3Context) || {}
	const isBrowser = () => typeof window !== 'undefined'

	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					siteUrl
				}
			}
		}
	`)

	const abbreviatedWallet = (wallet: string | null): string => {
		if (wallet === null || wallet === undefined) {
			return "<no account>"
		}
		return `${wallet?.slice(0, 5)}...${wallet?.slice(-4)}`
	}

	const hideConnectButton = (): boolean => {
		return !isBrowser()
	}

	return (
		<>
			<Helmet>
				<meta property="og:title" content="cubs.cloud"/>
				<meta property="og:type" content="website"/>
				<meta property="og:url" content={data.site.siteMetadata.siteUrl}/>
				<meta property="og:image" content={`${data.site.siteMetadata.siteUrl}/og.jpg`}/>
				<meta property="og:description" content="Honey Computing — Cloud"/>
				<meta property="og:image:width" content="1200"/>
				<meta property="og:image:height" content="630"/>
				<meta name="msapplication-TileColor" content="#FFFFFF"/>
				<meta name="msapplication-TileImage" content={`${data.site.siteMetadata.siteUrl}/mstile-144x144.png`}/>
				<title>{data.site.siteMetadata.title}</title>
				<script type="text/javascript" src="https://unpkg.com/web3@1.7.1/dist/web3.min.js" />
				<script type="text/javascript" src="https://unpkg.com/web3modal@1.9.5/dist/index.js" />
				<script type="text/javascript" src="https://unpkg.com/@walletconnect/web3-provider@1.7.4/dist/umd/index.min.js" />
				{[57, 72, 114, 120, 144, 152].map((size) =>
					<link rel="apple-touch-icon-precomposed" sizes={`${size}x${size}`} key={`${size}`}
					href={`${data.site.siteMetadata.siteUrl}/apple-touch-icon-${size}x${size}.png`}/>
				)}
				{[32, 16].map((size) =>
					<link rel="icon" type="image/png" sizes={`${size}x${size}`} key={`${size}`}
					href={`${data.site.siteMetadata.siteUrl}/favicon-${size}x${size}.png`}/>
				)}
			</Helmet>
			<header>
				<nav>
					<Link className={styles.honeyLogo} to="/"><img src="/img/honey-logo.png" alt="Honey Logo"/></Link>
					<Link className={styles.honeyLogo} to="/"><h1><span>Honey Computing</span></h1></Link>
					{altLogo ? <>
						<img src="/img/bear-market.png" alt="Bear Market" width={135}/>
					</>:<a className={styles.tbbLogo} target="_blank" href="https://opensea.io/collection/twobitbearsgentwo">
						<img src="/img/two-bit-bears_logo.png" alt="Two Bit Bears Logo"/>
					</a>}
				</nav>
				{web3Available == false ? <></> : (account === null || account === undefined) ?
					<button className={styles.connectButton} onClick={connectToWeb3} hidden={hideConnectButton()}><p>CONNECT</p></button> :
					<button className={styles.connectButton} onClick={disconnectFromWeb3}><p>CONNECTED {abbreviatedWallet(account)}</p></button>
				}
			</header>
			<main>
				<section className={styles.pageHeader}>
					{custom === null ? <h2>Welcome to the Two Bit Bears Cloud Breeding Server</h2> : <></>}
					<h3>{prompt}</h3>
					{custom !== null ? <h2>{custom}</h2> : <></>}
				</section>
				<section className={styles.pageContent}>
					{children}
				</section>
			</main>
		</>
	)
}
