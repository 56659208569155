import React from 'react'
import { Link } from 'gatsby'
import * as styles from './feature.module.css'

type FeatureProps = {
	title: string,
	description: string,
	destinationPath: string
}

export default function Feature({props}) {

	return (
		<Link className={styles.button} to={props.destinationPath}>
			<span>
				<div className={styles.icon}>
					{props.icon}
				</div>
				<div className={styles.text}>
					<h4>{props.title}</h4>
					<h5>{props.description}</h5>
				</div>
			</span>
		</Link>
	)
}
