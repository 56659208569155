import React from 'react'
import { Link } from 'gatsby'
import Arrow from '../svg/arrow.svg'
import * as styles from './simple-feature.module.css'

export default function SimpleFeature({props}) {

	return (
		<Link className={styles.button} to={props.destinationPath}>
			{props.title}<Arrow/>
		</Link>
	)
}
