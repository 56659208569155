import React from 'react'
import Layout from '../components/layout'
import Feature from '../components/feature'
import SimpleFeature from '../components/simple-feature'
// @ts-ignore
import Adopt from '../svg/adopt.svg'
// @ts-ignore
import Breed from '../svg/breed.svg'
// @ts-ignore
import Cub from '../svg/cub.svg'
// @ts-ignore
import CubBreed from '../svg/cub-breed.svg'

export default function IndexPage() {

	return (
        <Layout prompt="Would you like to Breed or Adopt Today?">
			<Feature props={{
				icon: <Breed/>,
				title: "Gen 1 Hyper-Realistic Cloud Breeding",
				description: "Holders with two Gen 1 Bears of the same species + Honey",
				destinationPath: "/breed/"
			}}/>
			<Feature props={{
				icon: <Adopt/>,
				title: "Gen 2 Cub Minting/Adoption",
				description: "Anyone with an appreciation for Hyper-Realistic Bears",
				destinationPath: "/adopt/"
			}}/>
			<Feature props={{
				icon: <Cub/>,
				title: "Gen 2 Cub Growth",
				description: "Cubs Must Grow Up Prior To Breeding Gen 3 Bears",
				destinationPath: "/grow/"
			}}/>
			<Feature props={{
				icon: <CubBreed/>,
				title: "Gen 2 Hyper-Realistic Cloud Breeding",
				description: "Holders with two Gen 2 Bears of the same species",
				destinationPath: "/gen-2-breed/"
			}}/>
			<SimpleFeature props={{
				title: "Visit the Bear Market",
				destinationPath: "/bear-market/"
			}}/>
        </Layout>
    )
}
